<!--
 * @Descripttion: 用户评论组件
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-06-19 14:45:37
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-20 10:05:42
-->
<template>
    <div class="comment-container comment-user">
      <div class="comment-box" >
        <UserAvatar
          :user="userInfos"
          size="38"
          fontSize="14"
          medalSize="58"
        ></UserAvatar>
        <CommonEditor
            buttonText="发布"
            @submit="addComment($event, item)"
            :placeholder="commentType === '2' ? '留下你的神评论～' : '发布你的评论~'"
            :limit="commentType === '7' ? '100' : '800'"
            :editorType="commentType === '7' ? 'okr' : 'comment'"
            ref="commonEditorRef"
          ></CommonEditor>
      </div>
      <div class="comment-list-box">
        <CommentList
          ref="commentListRef"
          :id="commentId"
          :userId="userId"
          :commentType="commentType"
          @update="updateTotal"
          >
          <template #buttonBox>
            <div class="button-box">
              <el-button type="text" size="mini" @click="commentListRef.loadMore" v-if="commentListRef.commentList.length < commentListRef.commentCount">
                展示更多评论>>
              </el-button>
              <el-button type="text" size="mini" @click="hiddenComment(commentListRef,item)" v-if="commentListRef.commentList.length > 10">
                收起
              </el-button>
            </div>
          </template>
          </CommentList>
      </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { reactive, computed, defineProps, toRefs, defineEmits, defineExpose, onMounted, nextTick } from 'vue'
import { feedsFeedComment } from '@/apis/blogs.js'
import { dialogMsg, myToast } from '@/utils/dialogMsg.js'
import mySocket from '@/mixin/socket.js'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import CommentList from './CommentList.vue'

const store = useStore()

const props = defineProps({
  commentType: { // 类型 1-微博 2-论坛 4-日志 7-okr
    type: [String, Number],
    default: '1'
  },
  id: { // 评论所属id
    type: [String, Number],
    default: 0
  },
  userId: { // 评论所属用户id
    type: Number,
    default: 0
  }
})

const emits = defineEmits(['updateCommentTotal', 'hiddenComment'])
const state = reactive({
  commonEditorRef: null,
  commentListRef: null,
  commentType: computed(() => props.commentType),
  commentId: computed(() => props.id),
  userId: computed(() => props.userId)
})
const { commonEditorRef, commentListRef, commentType, commentId, userId } = toRefs(state)
const userInfos = computed(() => store.state.userInfo.userInfos)
const { sendToSocket } = mySocket()

// 添加评论
const addComment = (data, item) => {
  feedsFeedComment({
    body: data.feed_content,
    id: state.commentId,
    commentable_type: state.commentType,
    target_user: state.userId,
    at_user: data.at_user,
    images_url: data.images_url
  })
    .then((res) => {
      if (res.code === 0) {
        myToast({ type: 'success', message: '评论成功' })
        sendToSocket([state.userId], { type: 5 }) // 通知被评论用户
        sendToSocket(data.at_user, { type: 4 }) // 通知被@的用户
        // 刷新页面数据
        state.commentListRef.getCommentList()
        state.commonEditorRef.resetEditorInfo()
      } else if (res.code === 30007) {
        myToast({ type: 'warning', message: '内容已被删除' })
      } else {
        dialogMsg('error', res.msg)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

// 收起评论列表
const hiddenComment = (ref) => {
  ref.hiddenComment()
  emits('hiddenComment')
}
// 更新评论总数
const updateTotal = (total) => {
  emits('updateCommentTotal', total)
}

onMounted(() => {
  nextTick(() => {
    state.commonEditorRef.focusInput()
  })
})

defineExpose({
  commonEditorRef,
  commentListRef
})
</script>

<style lang="less" scoped>

.comment-container{
    padding: 16px 0 8px;
    border: 1px solid #f1f1f1;
    border-top: none;
    .comment-box{
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 16px;
    }
    .comment-box,.comment-list-box{
      padding: 0 47px;
    }
    .button-box{
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
</style>
