<!--
 * @Descripttion: 已投用户组件
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-09 14:29:31
 * @LastEditors: tangjz
 * @LastEditTime: 2023-07-20 14:03:11
-->

<template>
  <div>
    <el-card class="checkuser-box">
      <div class="checkuser-close" @click="close"></div>
      <div class="checkuser-title">
        <span>投票</span>
      </div>
      <el-scrollbar height="390px" class="user-list" ref="checkUserScrollEl">
        <div class="user-item" v-for="item in userList" :key="item.name" v-show-tip>
          <UserAvatar
            :user="item"
            size="40"
            fontSize="12"
            medalSize="60"
          >
          </UserAvatar>
          <el-tooltip :content="item.name" placement="bottom" effect="light">
            <p class="user-name">{{item.name}}</p>
          </el-tooltip>
        </div>
      </el-scrollbar>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch, nextTick } from 'vue'

export default {
  emits: ['close'],
  props: {
    userList: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      userList: computed(() => props.userList),
      checkUserScrollEl: null
    })
    const close = () => {
      emit('close')
    }
    watch(() => state.userList, () => {
      nextTick(() => {
        state.checkUserScrollEl.setScrollTop(0)
        state.checkUserScrollEl.update()
      })
    })
    return {
      ...toRefs(state),
      close
    }
  }
}
</script>

<style lang="less" scoped>
  .checkuser-box{
    position: relative;
    border: none !important;
  }
  :deep(.el-card__body){
    padding: 0px;
  }
  .checkuser-title{
    border-bottom: 1px solid #F1F1F1;
    padding: 13px 32px 10px;
    span{
      color: @active-text-color;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        width: 55px;
        height: 3px;
        left: 50%;
        bottom: -11px;
        transform: translateX(-50%);
        background: @active-text-color;
        border-radius: 6px;
      }
    }
  }
  :deep(.user-list){
    &.el-scrollbar{
      width: 100%;
    }
    .el-scrollbar__view{
      display: flex;
      flex-wrap: wrap;
      padding: 17px 14px;
    }
    .user-item{
      margin: 9px 15px;
      width: 40px;
      .user-info{
        flex-direction: column;
      }
      .user-avatar{
        flex-direction: column;
        margin: 0;
      }
      .user-name{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #333333;
        text-align: center;
        transform: translateY(5px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
  .checkuser-close{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    height: 10px;
    background: url('../../../../assets/img/vote/close-icon.svg') no-repeat;
    cursor: pointer;
  }
</style>
