<!--
 * @Descripttion: 投票选项弹窗
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-19 15:44:06
 * @LastEditors: tangjz
 * @LastEditTime: 2024-01-16 17:37:09
-->

<template>
  <div>
    <el-card :class="['option-box', {'padding20' : toBatchAdd}]">
      <span class="option-close" @click="close"></span>
      <template v-if="!toBatchAdd">
        <p class="option-title">创建投票选项</p>
        <ul>
          <li v-for="(item, index) in options" :key="index" class="option-text">
            <input
              type="text"
              placeholder="输入投票选项"
              class="option-input-text"
              v-model="options[index]"
              :maxlength="50"
            />
            <p class="cut-add-icon">
              <i class="cut-icon" @click="delOption" v-if="index === options.length - 1 && options.length > 1"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="13" width="16.5" height="2.5" rx="1.25" fill="#999"/></svg></i>
              <i class="add-icon" @click="addOption" v-if="index === options.length - 1"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="13" width="16.5" height="2.5" rx="1.25" fill="#999"/><rect x="15.5" y="6.003" width="16.5" height="2.5" rx="1.25" transform="rotate(90.068 15.5 6.003)" fill="#999"/></svg></i>
            </p>
          </li>
        </ul>
        <p class="batch-btn"><span @click="toBatchAdd = true">批量添加</span></p>
        <el-button type="primary" @click="toCreateOption" :disabled="isDisabled" :class="['option-botton', {'is-disabled': isDisabled}]">创建选项</el-button>
      </template>
      <template v-if="toBatchAdd">
        <p class="batch-desc">批量添加 <span>(换行一次为一个选项)</span></p>
        <el-input
          v-model="batchData"
          :autosize="{ minRows: 7, maxRows: 20}"
          type="textarea"
        />
        <div class="button-box">
          <el-button type="primary" size="small" @click="toBatchAdd = false;batchData= ''">取消</el-button>
          <el-button type="primary" size="small" @click="batchAdd">添加</el-button>
        </div>
      </template>

    </el-card>
  </div>
</template>

<script>

import { computed, reactive, toRefs } from 'vue'
import { addVoteOption } from '@/apis/vote.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { debounce } from '@/utils/tools.js'
export default {
  emits: ['updateOptionList', 'close'],
  props: {
    voteId: {
      type: Number,
      default: 0
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      options: [''],
      isDisabled: computed(() => !!state.options.filter(item => item.trim() === '').length),
      voteId: computed(() => props.voteId),
      toBatchAdd: false,
      batchData: ''
    })

    const close = () => {
      emit('close')
    }
    // 删除投票选项
    const delOption = () => {
      state.options.pop()
    }
    // 添加投票选项
    const addOption = () => {
      state.options.push('')
    }

    // 创建投票选项
    const toCreateOption = debounce(() => {
      if (state.isDisabled) return
      const params = {
        vote_id: state.voteId,
        title_list: state.options
      }
      addVoteOption(params).then((res) => {
        if (res.code === 0) {
          emit('updateOptionList', state.voteId)
          emit('close')
          dialogMsg('success', '创建投票选项成功')
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }, 800)
    // 批量添加
    const batchAdd = () => {
      state.options = state.batchData.split(/\n/g).filter(i => i).map(i => i.trim())
      const isExceed = state.options.filter(i => i.length > 50)
      if (isExceed.length) {
        dialogMsg('warning', '每行字数不能超过50个')
      } else {
        state.toBatchAdd = false
        state.batchData = ''
      }
    }

    return {
      ...toRefs(state),
      close,
      toCreateOption,
      delOption,
      addOption,
      batchAdd
    }
  }
}
</script>

<style lang="less" scoped>
  .option-box{
    position: relative;
    padding: 23px 40px 30px;
    transition: none;
    &.padding20{
      padding: 20px;
    }
  }
  :deep(.el-card__body){
    padding: 0;
  }
  .option-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: @default-text-color;
    margin-bottom: 20px;
  }
  .option-text{
    position: relative;
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
    .option-input-text{
      width: 382px;
      height: 30px;
      outline: none;
      padding: 0 34px 0 16px;
      vertical-align: middle;
      border: 1px solid #F1F1F1;
      background-color: @bgColor;
      border-radius: 25px;
      position: relative;
    }
    .cut-add-icon{
      position: absolute;
      top: 2px;
      right: 0;
      display: flex;
      height: 28px;
      .cut-icon{
        margin-right: 20px;
        cursor: pointer;
      }
      .add-icon{
        cursor: pointer;
      }
    }

  }

  .option-botton{
    display: block;
    width: 306px;
    height: 44px;
    border-radius: 33px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    margin: 60px auto 0;
    &.is-disabled{
      background: @second-text-color;
    }

  }
  .option-close{
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    height: 10px;
    background: url('../../../../assets/img/vote/close-icon.svg') no-repeat;
    cursor: pointer;
  }
  .batch-btn {
    color: #5C53F6;
    line-height: 22px;
    text-align: right;
    margin: 16px 35px 0 0;
    span {
      cursor: pointer;
    }
  }
  .batch-desc {
    color: #000;
    font-weight: 600;
    span{
      color: @second-text-color;
      font-size: 14px;
      font-weight: 400;
    }
  }
  :deep(.el-textarea){
    margin: 10px 0 16px;
    & .el-textarea__inner{
      border-radius: 6px;
      border: 1px solid #F1F1F1;
      background: #F6F9FA;
      resize: none;
    }
    & .el-textarea__inner:hover {
      border-color: #F1F1F1;
    }
  }
  .button-box{
    display: flex;
    justify-content: flex-end;
    .el-button{
      width: 85px;
      height: 32px;
    }
    .el-button:first-child{
      background: #E4E3FC;
      border-radius: 30px;
      color: @active-text-color;
    }
  }
</style>
