<!--
 * @Descripttion: 投票动态组件
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-17 10:29:37
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-10-09 16:26:14
-->
<template>
    <div class="vote-box" v-loading="isLoading">
      <div class="vote-header">
        <span :class="['vote-form', { 'is-anonymous': !!voteData.is_anonymous }]">{{ !!voteData.is_anonymous ? '匿' : '实' }}</span>
        <p class="vote-title">
          这是投票标题 : {{ voteData.title }}
          <span>【{{ voteData.max_vote_nums > 1 ? ('多选' + (voteData.max_vote_nums === 999999 ? '' : ('-' + voteData.max_vote_nums + '票'))) : '单选' }}】</span>
        </p>
        <span :class="['option-inverted', disabled ? 'disabled' : '']" @click="getOptionSort" v-if="!!voteOptionList.length">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.981 9.125v11.878a.987.987 0 0 0 .296.705 1.01 1.01 0 0 0 .714.292 1.02 1.02 0 0 0 .713-.292.998.998 0 0 0 .296-.705V7.532c0-.331-.107-.654-.308-.919a1.548 1.548 0 0 0-.805-.552 1.566 1.566 0 0 0-.98.037c-.313.116-.58.33-.76.61L7.159 11.33a.99.99 0 0 0 .307 1.377 1.017 1.017 0 0 0 1.394-.303l2.121-3.28zM18.014 18.878V6.998a.99.99 0 0 0-.295-.706A1.007 1.007 0 0 0 17.007 6a1.015 1.015 0 0 0-.712.292.997.997 0 0 0-.295.705v13.474c0 .33.108.653.309.917a1.558 1.558 0 0 0 1.777.514 1.54 1.54 0 0 0 .759-.608l2.98-4.622a.992.992 0 0 0-.662-1.545 1.015 1.015 0 0 0-1.034.47l-2.115 3.281z" fill="#999"/></svg>
        </span>
      </div>
      <template v-if="!!voteOptionList.length">
        <ul class="vote-options">
          <li v-for="(option, index) in voteOptionList" :key="option.id">
            <template v-if="index < showLimit">
              <UserAvatar
                v-if="!voteData.is_option_fixed && !voteData.is_anonymous"
                :user="option.user"
                size="38"
                fontSize="14"
                medalSize="55"
              ></UserAvatar>
              <div class="option-content" :class="{'is-selected': option.has_voted}" >
                <p>{{`${index + 1}、${option.title.replace(/^\d{1,4}(、|\.)/g, '') }`}}</p>
                <template v-if="option.img_url">
                  <el-image :src="option.img_url"></el-image>
                </template>
                <span class="option-desc">
                  <template
                    v-if="option.has_voted"
                  >
                    <img class="option-selected" src="@/assets/img/vote/option-selected.svg" alt="active">
                  </template>
                  <i :class="['selected-num', disabled ? 'disabled' : '']" @click="getUserInfos(option)">{{ option.vote_nums > 0 ?  option.vote_nums : 0 }}</i>
                </span>

                <template
                    v-if="!voteData.is_finished && !voteData.is_option_fixed &&
                    (store.state.userInfo.userInfos &&
                  store.state.userInfo.userInfos.id === option.user_id)"
                  >
                  <img class="option-close" @click="delOption(option)" src="@/assets/img/vote/option-close.svg" alt="close">
                </template>
              </div>
              <span v-if="!voteData.is_finished" :class="['voted-icon', disabled ? 'disabled' : '']">
                <svg  @click="userSelectVote(option)" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.413 13.476c.378-.485.587-1.08.587-1.698 0-.98-.564-1.909-1.474-2.426a1.558 1.558 0 0 0-.771-.203h-5.396l.135-2.684a2.277 2.277 0 0 0-.147-.934 2.402 2.402 0 0 0-1.315-1.342A2.461 2.461 0 0 0 13.077 4c-1.17 0-2.205.764-2.515 1.86L8.63 12.651H5.72a.731.731 0 0 0-.51.205.689.689 0 0 0-.21.494v7.95c0 .387.322.699.72.699h13.53c.207 0 .41-.04.595-.117a2.857 2.857 0 0 0 1.49-1.393c.303-.627.357-1.34.151-2.002a2.77 2.77 0 0 0 .542-1.199c.08-.435.055-.883-.076-1.306.378-.485.587-1.08.587-1.697a2.902 2.902 0 0 0-.127-.81zM6.62 20.428v-6.204h1.822v6.204H6.62zm14.319-7.71l-.493.415.313.554c.14.25.188.538.136.817-.053.28-.201.533-.422.72l-.492.414.312.554c.14.25.187.538.135.817-.052.28-.2.533-.421.72l-.492.414.312.555a1.214 1.214 0 0 1-.177 1.43 1.28 1.28 0 0 1-.424.298H9.884v-6.272l2.238-7.874a.989.989 0 0 1 .95-.705.82.82 0 0 1 .581.239.774.774 0 0 1 .222.574l-.216 4.333h7.074c.4.239.648.64.648 1.057 0 .36-.162.701-.441.94h-.001z" fill="#999" stroke="#999" stroke-width=".2"/></svg>
              </span>
            </template>
          </li>
        </ul>
        <template v-if="showMore">
          <p
            :class="['show-more', isShowMoreText ? '' : 'hidden']"
            @click="showAll"
          >
            {{ isShowMoreText ? '展开更多' : '收起' }}
            <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 4.671L0 8V6.231l5.031-2.123v-.036L0 1.776V0l7 3.502v1.17zM15.063 4.671L8.063 8V6.231l5.03-2.123v-.036l-5.03-2.296V0l7 3.502v1.17zM23.094 4.671l-7 3.329V6.231l5.03-2.123v-.036l-5.03-2.296V0l7 3.502v1.17z" fill="#7972F0"/></svg>
          </p>
        </template>
        <template v-if="!voteData.is_option_fixed && !voteData.is_finished">
          <p :class="['option-add', { 'is-more': showMore }]" @click="addOption">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="13" width="16.5" height="2.5" rx="1.25" fill="#999"/><rect x="15.5" y="6.003" width="16.5" height="2.5" rx="1.25" transform="rotate(90.068 15.5 6.003)" fill="#999"/></svg>
          </p>
        </template>
      </template>
      <template v-else>
        <div :class="['empty-option', disabled ? 'disabled' : '']" @click="addOption">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="13" width="16.5" height="2.5" rx="1.25" fill="#999"/><rect x="15.5" y="6.003" width="16.5" height="2.5" rx="1.25" transform="rotate(90.068 15.5 6.003)" fill="#999"/></svg>
        </div>
      </template>
    </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { delVoteOption, selectVote, updateOptionList, getOptionUsers } from '@/apis/vote.js'
import { dialogMsg, confirm } from '@/utils/dialogMsg.js'
export default {
  emits: ['updateOptions', 'addOption', 'getOptionUser', 'updateVoteNum'],
  props: {
    voteData: {
      type: Object,
      default: () => {}
    },
    keyId: { // 对应动态模块id
      type: Number,
      default: 0
    },
    showCommentAuto: { // 是否为动态详情
      type: Boolean,
      default: false
    },
    disabled: { // 是否不可操作，定时和草稿状态为disabled
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      voteData: computed(() => props.voteData),
      voteOptionList: computed(() => state.voteData.options),
      showMore: computed(() => { return state.voteData.options_all_nums > 3 }),
      disabled: computed(() => props.disabled),
      isShowMoreText: true,
      isLoading: false,
      disabledVote: computed(() => state.selectNum === state.voteData.max_vote_nums),
      selectNum: 0,
      isNumSort: false,
      optionUser: {},
      keyId: computed(() => props.keyId),
      showLimit: 3
    })

    // 获取已投用户列表
    const getUserList = async (ids) => {
      const params = {
        vote_id: state.voteData.id,
        option_id: ids
      }
      const res = await getOptionUsers(params)
      if (res.code === 0) {
        res.data.list.forEach(item => {
          state.optionUser[state.voteData.id + '_' + item.option_id] = item.users
        })
      }
    }

    // 获取已投的用户详情
    const getSelectUser = () => {
      if (state.voteData.is_anonymous) return
      const votedList = state.voteOptionList.filter((item) => {
        return item.vote_nums
      }).map(v => v.id)
      if (votedList.length) {
        getUserList(votedList)
      }
    }
    const init = () => {
      state.selectNum = state.voteData.voted_nums
      getSelectUser()
      // 初始化已结束投票的投票选项排序状态
      state.isNumSort = !!state.voteData.is_finished
    }
    // 初始化已选票数和获取投票用户详情
    init()

    // 更新选项列表
    const updateOptions = (params) => {
      return new Promise((resolve, reject) => {
        updateOptionList(params)
          .then((res) => {
            state.isLoading = false
            if (res.code === 0) {
              resolve(res)
            }
          }).catch((res) => {
            reject(res)
          })
      })
    }

    // 收起展开更多
    const showAll = () => {
      state.isShowMoreText = !state.isShowMoreText
      if (!state.isShowMoreText) { // 展开调用接口
        console.log('SHOWALL', state.disabled)
        // 不需要调用接口
        if (state.disabled) {
          state.showLimit = state.voteOptionList.length
          return false
        }
        const params = {
          vote_id: state.voteData.id,
          sort_type: state.isNumSort ? 'vote_nums_desc' : 'create_at_desc',
          limit: 999999
        }
        state.isLoading = true
        updateOptions(params)
          .then(res => {
            state.isLoading = false
            if (res.code === 0) {
              state.voteData.options = res.data.data
              state.voteData.options_all_nums = res.data.total
              state.showLimit = res.data.data.length
              getSelectUser()
              // 投票未结束，更新可投票数
              if (state.voteData.is_finished) return
              state.selectNum = res.data.voted_nums
              emit('updateVoteNum', { id: props.keyId, num: res.data.voted_nums })
            }
          }).catch(res => {
            console.log(res)
          })
      } else { // 收起
        const heigth = !props.showCommentAuto ? (document.querySelector(`#comment_${props.keyId}`).offsetTop - 70) : 0
        if (heigth < document.scrollingElement.scrollTop) {
          document.documentElement.style.scrollBehavior = 'smooth'
          document.scrollingElement.scrollTop = heigth
          setTimeout(() => {
            document.documentElement.style.scrollBehavior = 'auto'
          }, 1000)
        }
        if (state.disabled) {
          state.showLimit = 3
        } else {
          state.voteData.options = state.voteData.options.slice(0, 3)
        }
      }
    }

    // 删除投票选项
    const delOption = (item) => {
      confirm({
        message: '确认删除该投票选项吗？',
        success: () => {
          const params = {
            vote_id: state.voteData.id,
            option_id: item.id
          }
          delVoteOption(params).then((res) => {
            if (res.code === 0) {
              dialogMsg('success', '删除成功')
              emit('updateOptions', state.voteData.id)
              if (item.has_voted) {
                state.selectNum -= 1
              }
            } else {
              dialogMsg('success', res.msg)
            }
          }).catch((res) => {
            console.log(res)
          })
        },
        cancel: function () {}
      })
    }

    // 添加投票选项
    const addOption = () => {
      if (state.disabled) {
        return
      }
      if (!state.voteData.is_finished) {
        emit('addOption')
      } else {
        dialogMsg('error', '投票已结束')
      }
    }

    // 进行投票
    const userSelectVote = (item) => {
      if (state.disabled) {
        return
      }
      if (!item.has_voted && state.disabledVote) {
        confirm({
          message: '你的投票次数已用完，继续投票将会更换最早投票的选项',
          confirmButtonText: '更换',
          success: () => {
            voteSelected(item)
          },
          cancel: () => {}
        })
      } else {
        voteSelected(item)
      }
    }
    const voteSelected = (item) => {
      const params = {
        vote_id: state.voteData.id,
        option_id: item.id
      }
      selectVote(params).then((res) => {
        if (res.code === 0) {
          if (res.data.type === 1) {
            // 原先是已投的情况，点击投票 - 多端或者多页面登录
            if (item.has_voted) return
            if (state.disabledVote && !res.data.cancel_option_id) {
              emit('updateOptions', state.voteData.id)
              return
            }
            // 投票
            item.has_voted = 1
            !state.voteData.is_anonymous && getUserList([item.id])
            item.vote_nums = res.data.vote_nums
            // 替换最早选项
            if (!res.data.cancel_option_id) {
              state.selectNum += 1
            } else {
              const cancelOption = state.voteOptionList.find(item => item.id === res.data.cancel_option_id)
              cancelOption.has_voted = 0
              cancelOption.vote_nums -= 1
              state.optionUser[state.voteData.id + '_' + cancelOption.id] = state.optionUser[state.voteData.id + '_' + cancelOption.id]?.filter(i => i.id !== store.state.userInfo.userInfos.id) || []
            }
          } else {
            // 原先是未投的情况，取消投票 - 多端或者多页面登录
            if (!item.has_voted) return
            // 取消投票
            item.has_voted = 0
            state.selectNum -= 1
            item.vote_nums -= 1
            state.optionUser[state.voteData.id + '_' + item.id] = state.optionUser[state.voteData.id + '_' + item.id]?.filter(i => i.id !== store.state.userInfo.userInfos.id) || []
          }
          // 更新剩余可投数
          emit('updateVoteNum', { id: props.keyId, num: state.selectNum })
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }
    // 投票排序
    const getOptionSort = () => {
      if (state.disabled) {
        return
      }
      // 切换排序方式
      state.isNumSort = !state.isNumSort
      const params = {
        vote_id: state.voteData.id,
        sort_type: state.isNumSort ? 'vote_nums_desc' : 'create_at_desc',
        limit: state.isShowMoreText ? 3 : 999999
      }
      state.isLoading = true
      updateOptions(params)
        .then((res) => {
          state.isLoading = false
          if (res.code === 0) {
            state.voteData.options = res.data.data
            state.voteData.options_all_nums = res.data.total
            getSelectUser()
            // 投票未结束，更新可投票数
            if (state.voteData.is_finished) return
            state.selectNum = res.data.voted_nums
            emit('updateVoteNum', { id: props.keyId, num: res.data.voted_nums })
          }
        }).catch((res) => {
          console.log(res)
        })
    }

    // 弹出用户弹窗
    const getUserInfos = (item) => {
      if (state.disabled) {
        return
      }
      if (state.voteData.is_anonymous) {
        dialogMsg('warning', '匿名投票不能查看已投票者信息哦！')
      } else if (!item.vote_nums) {
        dialogMsg('warning', '选项还没有投过票，快来投票吧！')
      } else {
        emit('getOptionUser', state.optionUser[state.voteData.id + '_' + item.id])
      }
    }

    return {
      ...toRefs(state),
      store,
      showAll,
      delOption,
      addOption,
      userSelectVote,
      getOptionSort,
      getUserInfos
    }
  }
}
</script>

<style lang="less" scoped>
.vote-box{
  position: relative;
  margin-bottom: 30px;
  .vote-header{
    position: relative;
    margin: 10px 0 13px;
    display: flex;
    // align-items: center;
    .vote-form{
      min-width: 18px;
      width: 18px;
      height: 18px;
      background: #19A3E8;
      border-radius: 4px;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      line-height: 17px;
      margin-top: 2px;
      margin-right: 8px;
      color: @colorWhite;
      transform: scale(11/12);
      &.is-anonymous{
        background: @second-text-color;
      }
    }
    .option-inverted{
      position: absolute;
      right: 0px;
      top: 0px;
      &.disabled{
        cursor: not-allowed;
      }
    }
    .vote-title{
      padding-right: 45px;
      font-size: 14px;
      line-height: 22px;
      color: @default-text-color;
    }

  }
  .vote-options{
    margin-left: 5px;
    li{
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0px;
      }

      .user-avatar{
        margin-right: 16px;
      }
      .option-content{
        position: relative;
        padding: 11px 0px 11px 8px;
        min-width: 560px;
        width: 100%;
        min-height: 40px;
        background: #F4F4F4;
        border: 1px solid #F1F1F1;
        border-radius: 6px;
        padding: 11px 40px 11px 8px;
        color: @second-text-color;
        &.is-selected{
          background: @bgColor;
          padding-right: 75px;
          &+.voted-icon{
            path{
              fill: @active-text-color;
            }
          }
        }
        .el-image{
          width: 156px;
          height: 156px;
          margin-top: 10px;
          border-radius: 6px;
        }
        .option-desc{
          position: absolute;
          top: 20px;
          right: 20px;
          transform: translateY(-50%);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          min-width: 60px;
        }
        .option-selected{
          margin-right: 19px;
        }
        .option-close{
          position: absolute;
          top: -5px;
          right: -5px;
          cursor: pointer;
        }
        .selected-num{
          font-size: 14px;
          font-weight: 700;
          color: @second-text-color;
          font-style: normal;
          cursor: pointer;
          &.disabled{
            cursor: not-allowed;
          }
        }

      }
      .voted-icon{
        margin-left: 15px;
      }
    }
  }
  .show-more{
    text-align: center;
    margin: 20px auto 0;
    width: 90px;
    &.hidden{
      width: 70px;
      svg{
        transition: none;
      }

    }
  }
  .option-add,
  .option-inverted,
  .voted-icon{
    width: 28px;
    height: 28px;
    svg{
      border-radius: 50%;
      &:hover{
        background: #ECECF1;
        path{
          fill: @active-text-color;
        }
        rect{
          fill: @active-text-color;
        }
      }
    }
    &.disabled{
      cursor: not-allowed;
    }
  }
  .option-add{
    position: absolute;
    right: 0px;
    bottom: -30px;
    &.is-more{
      bottom: -2px;
    }
  }
  .option-add,
  .voted-icon{
    cursor: pointer;
  }
  .empty-option{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    left: 0px;
    top: 4px;
    background: @bgColor;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    cursor: pointer;
    &.disabled{
      cursor: not-allowed;
    }
  }

}
</style>
